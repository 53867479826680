import React, { FC, createContext, useEffect, useState } from 'react';

import { ModalProps } from '../../types';
import { classes } from './Provider.st.css';

type ModalComponent<T = unknown> = FC<ModalProps & T>;

type ModalPropsKeys = keyof ModalProps;

interface ModalContextType {
  showModal<T>(
    modal: ModalComponent<T>,
    additionalProps: Omit<T, ModalPropsKeys>,
  ): void;
  hideModal(): void;
}

export const ModalsContext = createContext<ModalContextType | null>(null);

export const ModalsContextProvider: FC = ({ children }) => {
  const [Modal, setModal] = useState<ModalComponent | null>(null);
  const [props, setProps] = useState<Record<string, unknown> | null>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (Modal) {
      setIsOpen(true);
    }
  }, [Modal]);

  useEffect(() => {
    if (!isOpen) {
      setModal(null);
      setProps(null);
    }
  }, [isOpen]);

  const value = {
    showModal: <T,>(
      modal: ModalComponent<T>,
      additionalProps: Omit<T, ModalPropsKeys>,
    ) => {
      setProps(additionalProps);
      setModal(() => modal as ModalComponent);
    },
    hideModal: () => setIsOpen(false),
  };

  return (
    <ModalsContext.Provider value={value}>
      <>
        {children}
        {Modal && (
          <div className={classes.modalWrapper}>
            <Modal
              {...props}
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
            />
          </div>
        )}
      </>
    </ModalsContext.Provider>
  );
};
